import Identite from '@/components/identite/identite.vue'
import communLangage from '@/commun/commun.langage';
import axios from 'axios'
import AOS from 'aos';

import fichier from '@/utils/fichier.js'

export default {
     name: 'InformationsGenerales',

     components: {
          Identite
     },

     data() {
          return {
               telephone: null,
               permis: null,
               titres: {},
               langues: {},
               musique: null,
               sports: [],
               texte: null,
               infosImg: {},

               pdf: null,
          }
     },

     computed: {
          langage() {
               return communLangage.langage;
          }
     },

     methods: {
          recupererTexte() {
               axios.get(process.env.BASE_URL + 'assets/textes/accueil/informationsGenerales.json').then(result => {
                    this.telephone = this.langage == "fr" ? result.data.infos.fr.telephone : result.data.infos.en.telephone;
                    this.permis = this.langage == "fr" ? result.data.infos.fr.permis : result.data.infos.en.permis;
                    this.musique = this.langage == "fr" ? result.data.infos.fr.musique : result.data.infos.en.musique;
                    this.sports = this.langage == "fr" ? result.data.infos.fr.sports : result.data.infos.en.sports;
                    this.langues = this.langage == "fr" ? result.data.infos.fr.langues : result.data.infos.en.langues;
                    this.texte = this.langage == "fr" ? result.data.infos.fr.texte : result.data.infos.en.texte;
                    this.titres = this.langage == "fr" ? result.data.titres.fr : result.data.titres.en;
                    console.log(this.langues)

                    this.infosImg = this.langage == "fr" ? result.data.infosImg.fr : result.data.infosImg.en;
               });
          },

          recupererCV() {
               fichier.recupererfichierCV(`Thomas_BEAUD_CV_${this.langage.toUpperCase()}`).then((result) => {
                    this.pdf = result.data;
               })
          }
     },

     watch: {
          langage: function () {
               this.recupererTexte();
          }
     },

     created() {
          this.recupererTexte();
          this.recupererCV();
          AOS.init();

     }
}