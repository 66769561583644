<template>
  <div class="">
    <div class="titre-height pt-2">
    <h1 class="font-weight-bold text-center p-3 m-0" data-aos="zoom-in" data-aos-duration="1500">
      {{ titres.section }}
    </h1>
    <hr class="border-info border-2 w-75 mt-0 mt-2" />
    </div>
    <div
      style="font-size: 130%"
      class=""
      data-aos="fade-up"
      data-aos-duration="1500"
      data-aos-delay="600"
    >
      <div v-for="(entreprise, index) in entreprises" :key="index">
        <div
          class="d-flex justify-content-around text-left d-flex-tel-reverse"
          :class="index % 2 == 0 ? 'flex-row ' : 'flex-row-reverse'"
          :id="entreprise.code"
        >
          <div
            style="flex-basis: 45%"
            class="d-flex align-items-center mt-5"
            :class="{
              'justify-content-end': index % 2 == 0,
              'justify-content-start': index % 2 != 0,
              'margin-top-5': index == 0,
            }"
          >
            <div
              class="bulle"
              :class="index % 2 == 0 ? 'bulleDroite' : 'bulleGauche'"
            >
              <div>
                <h4>{{ titres.bulle }}</h4>
                <p v-html="entreprise.bulle" class="text-justify"></p>
              </div>
            </div>
          </div>
          <div
            style="flex-basis: 5%"
            class="d-flex flex-column align-items-center"
          >
            <div class="triangle" v-if="index == 0"></div>
            <div class="h-100 w-25 frise-chrono">
              <div
                class="barre-frise-chrono-gauche"
                style="top: 40px"
                v-if="index != 0"
              ></div>
              <div class="barre-frise-chrono-droite" style="bottom: 0px"></div>
            </div>
          </div>
          <div
            class="card d-flex align-items-center p-3 mt-5"
            style="flex-basis: 45%"
            :class="index == 0 ? 'margin-top-5' : ''"
          >
            <img
              :src="require(`@/assets/logo/${entreprise.img}`)"
              :alt="`Logo ${entreprise.code}`"
              :titre="`Logo ${entreprise.code}`"
              class="logo-entreprise"
            />

            <div class="periode badge badge-info">{{ entreprise.annee }}</div>
            <div class="mt-1 w-100">
              <h4 class="mb-4" v-html="entreprise.titre"></h4>
              <p class="text-justify">
                <img
                  v-if="entreprise.imgLogiciel"
                  :src="require(`@/assets/logo/${entreprise.imgLogiciel}`)"
                  style="float: right; border: 1px solid #e0e0e0"
                  class="mt-2 ml-3 mr-2 logo-logiciel"
                />
                <span v-html="entreprise.description"></span>
              </p>
              <Transition>
                <div v-if="entreprise.afficherPlus" class="afficher-plus">
                  <div>
                    <h4 class="font-weight-bold">{{ titres.missions }}:</h4>
                    <div class="ml-5">
                      <ul>
                        <li
                          v-for="(mission, index) in entreprise.missions"
                          :key="index"
                          class="mt-2"
                        >
                          <span v-html="mission" class="mission"></span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="mt-4">
                    <h4 class="font-weight-bold">{{ titres.technologies }}:</h4>
                    <div
                      class="
                        d-flex
                        flex-wrap
                        justify-content-between
                        align-items-center
                      "
                    >
                      <p
                        v-for="(techno, index) in entreprise.technologies"
                        :key="index"
                      >
                        <span v-html="techno"></span>
                      </p>
                    </div>
                  </div>
                </div>
              </Transition>
              <div class="text-center mb-2">
                <a
                  v-if="entreprise.plusInformation"
                  class="font-weight-normal"
                  :class="entreprise.afficherPlus ? 'text-danger' : 'text-info'"
                  :href="`#${entreprise.code}`"
                  style="cursor: pointer"
                  @click="() => {entreprise.afficherPlus = !entreprise.afficherPlus; afficherBulle(entreprise.afficherPlus)}"
                >
                  <span class="fa fa-plus mr-1"></span>
                  <span>{{ titres.plus }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./parcoursProfessionel.js"></script>

<style scoped>
.flex-row-reverse {
  text-align: end;
}
.card {
  border-color: #666565;
}
.periode {
  margin: 2%;
  font-size: 90%;
}
.mission::v-deep(i) {
  font-size: 90%;
}

@media (min-width: 600px) {
  .margin-top-5 {
    margin-top: 5% !important;
  }

  .logo-entreprise{
    width: 40%;
  }
}

@media (max-width: 600px) {
  .logo-logiciel{
    width: 60%;
  }

  .logo-entreprise{
    width: 90%;
  }
}

.v-leave-active {
  transition: opacity 0.1s ease;
}

.v-enter-active {
  transition: opacity 1.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>