import axios from 'axios'
import communLangage from '@/commun/commun.langage.js';

export default {
     name: 'Formation',

     data() {
          return {
               afficherPlus: false,
               cpe: {},
               iut: {},
               lycee: {},

               ecoles:[],
               titres:{}
          }
     },

     computed: {
          langage() {
               return communLangage.langage;
          }
     },

     methods: {
          recupererTexte() {
               axios.get(process.env.BASE_URL + 'assets/textes/accueil/formation.json').then(result => {

                    this.titres = this.langage == "fr" ? result.data.titres.fr : result.data.titres.en;
                    result.data.ecoles.forEach(element => {
                         var tmp = this.langage == "fr" ? element.fr : element.en;
                         tmp.nom = element.nom;
                         tmp.code = element.code;
                         tmp.img = element.img;
                         this.ecoles.push(tmp)

                    });

               });
          },

     },

     watch: {
          langage: function () {
               this.recupererTexte();
          }
     },

     created() {
          this.recupererTexte();
     }
}