<template>
    <div class="triangle-prochaine-page"
         :class="{'triangle-bas' : orientation == 'bas',
                  'triangle-haut' : orientation == 'haut'}">
        
          <a :href="`#${nomHref}`" @click="cacherHeader()"
            ><span class="fa fa-2x fa-chevron-right text-info"></span
          ></a>
        </div>
</template>

<script src="./chevronProchainePage.js"></script>