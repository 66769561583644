<template>
  <div style="position: relative">
    <div style="position: absolute; top: 4%; right: 1%;z-index:100" class="d-flex align-items-center">
      <a :href="pdf" class="cursor-pointer mr-3" download="Thomas_BEAUD_CV"> 
      <img
          src="@/assets/logo/cv.png"
          alt="CV"
          class="logo-linkedin"
          style="width: 60px"
          :title="infosImg.title?.cv"
        />
      </a>
      <a href="https://fr.linkedin.com/in/thomas-beaud" class="cursor-pointer">
        <img
          src="@/assets/logo/linkedin.png"
          alt="logo linkedin"
          class="logo-linkedin"
          style="width: 60px"
          :title="infosImg.title?.linkedin"
        />
      </a>
    </div>
    <div class="titre-height pt-2">
      <h1
        class="p-3 m-0 font-weight-bold text-center"
        data-aos="zoom-in"
        data-aos-duration="1500"
      >
        {{ titres.section }}
      </h1>
      <hr class="border-info border-2 w-75 mt-0 mt-2" />
    </div>
    <div
      class="
        d-flex
        align-items-center
        justify-content-center
        d-flex-tel
        mr-2
        mt-3
        infos-height
      "
      style="font-size: 110%"
      data-aos="fade-up"
      data-aos-duration="1500"
      data-aos-delay="600"
    >
      <div
        style="flex-basis: 35%; flex-shrink: 1"
        class="card pt-3 pb-3 pr-3 pl-3 mode-tel-p-2"
      >
        <div class="d-flex flex-row align-items-center">
          <img
            src="@/assets/logo/CI.jpg"
            style="max-width: 37%; flex-basis: 45%"
            class="photo mr-3 mode-tel-max-width-35"
            :alt="infosImg.alt?.maPhoto"
          />
          <Identite
            class="infosGenerales mode-tel-font-size-90 mode-tel-mr-0"
            style="width: 65%; flex-basis: 65%"
            :alt="infosImg.alt?.infos"
          />
        </div>
        <b class="mt-4 text-justify" style="font-size: 110%" v-html="texte"></b>
      </div>
      <div
        style="flex-basis: 65%; flex-grow: 3"
        class="w-100 h-100 ml-3 mr-2 d-flex flex-column justify-content-center"
      >
        <div
          class="d-flex align-items-center justify-content-around d-flex-tel"
        >
          <div class="mr-3 mode-tel-mr-0" style="flex-basis: 54%">
            <div
              class="
                d-flex
                align-items-center
                justify-content-around
                d-flex-tel
              "
            >
              <div
                class="
                  d-flex
                  align-items-center
                  p-3
                  petite-card
                  w-100
                  mr-3
                  mode-tel-mr-0 mode-tel-mt-4
                "
              >
                <img
                  src="@/assets/logo/phone-call.png"
                  :alt="infosImg.alt?.telelpohne"
                  style="width: 50px"
                  class="ml-2"
                />
                <b
                  class="
                    d-flex
                    align-items-center
                    justify-content-center
                    w-100
                    ml-2
                    mr-2
                  "
                  >{{ telephone }}</b
                >
              </div>
              <div
                class="
                  d-flex
                  align-items-center
                  p-3
                  petite-card
                  w-100
                  mode-tel-mt-4
                "
              >
                <img
                  src="@/assets/logo/address.png"
                  :alt="infosImg.alt?.adresse"
                  style="width: 50px"
                  class="ml-2"
                />
                <div
                  class="
                    d-flex
                    flex-column
                    align-items-center
                    justify-content-center
                    w-100
                    ml-2
                    mr-2
                  "
                >
                  <b> 59 rue Pied La Vigne </b>
                  <b>07130 Cornas </b>
                </div>
              </div>
            </div>
            <div
              class="
                d-flex
                align-items-center
                justify-content-around
                d-flex-tel
                mt-4
              "
            >
              <div
                class="
                  d-flex
                  align-items-center
                  p-3
                  petite-card
                  mt-2
                  w-100
                  mr-3
                  mode-tel-mr-0 mode-tel-mt-4
                "
                style=""
              >
                <img
                  src="@/assets/logo/email.png"
                  :alt="infosImg.alt?.email"
                  style="width: 50px"
                  class="ml-2"
                />
                <b
                  class="
                    d-flex
                    align-items-center
                    justify-content-center
                    w-100
                    ml-2
                    mr-2
                  "
                  >beaudthomas@gmail.com</b
                >
              </div>
              <div
                class="
                  d-flex
                  align-items-center
                  p-3
                  petite-card
                  mt-2
                  mode-tel-mt-4
                  w-100
                "
              >
                <img
                  src="@/assets/logo/voiture.png"
                  :alt="infosImg.alt?.voiture"
                  style="width: 50px"
                  class="ml-2"
                />

                <b
                  class="
                    d-flex
                    align-items-center
                    justify-content-center
                    w-100
                    ml-2
                    mr-2
                  "
                  >{{ permis }}</b
                >
              </div>
            </div>
          </div>
          <div style="flex-basis: 46%">
            <div
              class="d-flex align-items-center p-3 petite-card mode-tel-mt-4"
            >
              <img
                src="@/assets/logo/languages.png"
                :alt="infosImg.alt?.langages"
                style="width: 50px"
                class="ml-1 mr-2"
              />
              <b class="w-100 ml-2 mr-2">
                <p
                  v-for="(langue, index) in langues"
                  :key="index"
                  class="d-flex align-items-center"
                >
                {{`@/assets/logo/${langue.img}`}}
                  <img
                    :src="require(`@/assets/logo/${langue.img}`)"
                    :alt="`Logo ${langue.code}`"
                    :titre="`Logo ${langue.code}`"
                    style="width: 10%"
                    class="mr-2"
                  />
                  <span v-html="langue.texte"></span></p
              ></b>
            </div>
          </div>
        </div>
        <div class="mt-5">
          <div
            class="
              d-flex
              align-items-center
              p-2
              pr-3
              pl-3
              petite-card
              w-100
              mr-3
            "
          >
            <img
              src="@/assets/logo/mode-de-vie.png"
              :alt="infosImg.alt?.interets"
              style="width: 50px"
              class="ml-2 mr-2"
            />
            <span
              class="
                d-flex
                align-items-center
                justify-content-around
                w-100
                ml-2
                mr-2
              "
            >
              <span class="mr-2">
                <b>{{ titres.musique }}:</b>
                {{ musique }}</span
              ><span>
                <b>{{ titres.sport }}: </b>
                <span
                  v-for="(sport, indexSports) in sports"
                  :key="indexSports"
                  class="mr-1"
                >
                  {{ sport }}{{ indexSports + 1 != sports.length ? "," : "" }}
                </span>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./informationsGenerales.js"></script>

<style scoped>
.infosGenerales {
  border-radius: 50px;
}
.photo {
  border-radius: 30px;
}
.logo-linkedin {
  border-radius: 10px;
}
.card {
  border-radius: 0 50px 50px 0 !important;
  background-color: #f5f5f5;
  border: 0px;
}
.petite-card {
  border-radius: 50px 50px 50px 50px;
  background-color: #f5f5f5;
  border: 0px;
}

@media (max-width: 800px) {
  .d-flex-tel {
    flex-direction: column;
  }
  .photo {
    margin: 2% 0% 2% 0%;
  }
}

@media (min-width: 800px) {
}
</style>