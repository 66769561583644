import communLangage from '@/commun/commun.langage';
import axios from 'axios'


export default {
     name: 'ParcoursProfessionel',

     data() {
          return {
               
               entreprises:[],
               titres:{},
          }
     },

     computed: {
          langage() {
               return communLangage.langage;
          }
     },

     methods: {
          recupererTexte() {
               axios.get(process.env.BASE_URL + 'assets/textes/accueil/parcoursProfessionnel.json').then(result => {
                    this.titres = this.langage == "fr" ? result.data.titres.fr : result.data.titres.en;

                    
                    result.data.entreprises.forEach(element => {
                         var tmp = this.langage == "fr" ? element.fr : element.en;
                         tmp.nom = element.nom;
                         tmp.code = element.code;
                         tmp.afficherPlus = false;
                         tmp.plusInformation = element.plusInformation;
                         tmp.img = element.img;
                         tmp.imgLogiciel = element.imgLogiciel;

                         this.entreprises.push(tmp);
                    });

               });
          },
     },

     watch: {
          langage: function () {
               this.recupererTexte();
          }
     },

     created() {
          this.recupererTexte();
     }
}