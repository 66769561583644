<template>
  <div style="position: relative">
    <!-- <button @click="$router.push('/banque')">Banque</button> -->
    <div class="background-color-body w-100" style="">
      <section style="position: relative" class="body-height" id="infoGenerales">
        <InformationsGenerales />
        <ChevronProchainePage nomHref="formation"/>
      </section>
      <hr class="border-info"/>
      <section class="height" style="position:relative" id="formation">
        <!-- <ChevronProchainePage nomHref="app" orientation="haut"/> -->
        <Formation />
        <ChevronProchainePage nomHref="parcoursPro" />
      </section>
      <hr class="border-info"/>
      <section style="position:relative" id="parcoursPro">
        <!-- <ChevronProchainePage nomHref="formation" orientation="haut"/> -->
      <ParcoursProfessionel />
      </section>
      
        <button v-scroll-btn-top @click="test()"  class="mr-4 btn" style="position: sticky; bottom: 2%; left:100%; display: none !important">
          <span class="fa fa-2x fa-arrow-up"></span>
        </button>
    </div>

    <!-- <div>
        <ul>
            <li>Création de sites web (sites virtrines, blogs personnels)</li>
            <li>Création de logiciels interne/ logiciels pour des clients</li>
            <li>Mise à niveau de la technologie web utilisée/ refonte du code (Changer pour vuejs -> language de plus en plus utilisé, beaucoup d'aide sur internet, technologie qui ade l'avenir)</li>
            <li class="hidden">Création d'application mobile</li>
        </ul>
    </div> -->
  </div>
</template>

<script src="./accueil.index.js"></script>

<style scoped>


.item {
  /* Set the minimum height of the items to be the same as the height of the scroll-snap-container.*/
  min-height: 500px;
}
hr {
  margin-top: 3%;
  margin-bottom: 0%;
}
</style>