import moment from 'moment'
import communLangage from '@/commun/commun.langage';
import axios from 'axios'
export default {
    name:"Identite",

    data(){
        return{
            age:null,
            titres:{}
        }
    },

    computed: {
        langage() {
             return communLangage.langage;
        }
   },

   methods: {
        recupererTexte() {
             axios.get(process.env.BASE_URL + 'assets/textes/accueil/informationsGenerales.json').then(result => {
                  this.titres = this.langage == "fr" ? result.data.titres.fr : result.data.titres.en;

             });
        }
   },

   watch: {
        langage: function () {
             this.recupererTexte();
        }
   },

    created(){
        moment.locale();

        this.age = moment().diff('2000-02-05', 'years');

        this.recupererTexte();
    }
}