import axios from "axios";

export default {

    async recupererfichier(path) {
        return axios.get(process.env.BASE_URL + path).then(result => {
            return {"data":result.config.url};
        });
    },

    async recupererfichierCV(nom) {
        return axios.get(process.env.BASE_URL + `assets/fichiers/cv/${nom}.pdf`).then(result => {
            return {"data":result.config.url};
        });
    },

    async recupererfichiersCV() {
        var fichiersCV = [];
        await axios.get(process.env.BASE_URL + `assets/fichiers/cv/Thomas_BEAUD_CV_FR.pdf`).then((result) => {
            return fichiersCV.push({"url":result.config.url,"code":"CV_FR"});
        });
        await axios.get(process.env.BASE_URL + `assets/fichiers/cv/Thomas_BEAUD_CV_EN.pdf`).then((result) => {
            return fichiersCV.push({"url":result.config.url,"code":"CV_EN"});
        });

        return {"data":fichiersCV};
    }
}

