import communGeneral from "../../commun/commun.general"


export default {
  name: "ChevronProchainePage",

  data() {
    return {

    }
  },

  props: {
    nomHref: {
      default: ""
    },

    orientation: {
      default: "bas"
    }
  },

  methods: {
    cacherHeader() {
      if (this.orientation == 'haut') {
        communGeneral.vscroll = false;
        setTimeout(() => { communGeneral.vscroll = true }, 1500);
      }
    },

  },

  created() {

  }
}